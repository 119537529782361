import Vue from 'vue'

Vue.filter('feePeriod', (value) => {
    if (!value) return value;

    let val = value;
    switch(value.toLowerCase())
    {
        
        case 'o':
            val = 'One Time';
            break;
        case 'm':
            val = 'Monthly';
            break;
        case'y':
            val = 'Yearly';
            break;
        default:
            val =  value;
            break;
    }
    
    return val;
})
