import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vue-head'
import 'vuetify/dist/vuetify.min.css'
import VueLuxon from "vue-luxon";
import VueTheMask from 'vue-the-mask';
import VueConfetti from 'vue-confetti';
import VueSweetalert2 from 'vue-sweetalert2';
import UUID from "vue-uuid";

import kendo from '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import { MediaPlayerInstaller  } from '@progress/kendo-mediaplayer-vue-wrapper';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';



import { VTextField } from 'vuetify/lib'
import VCurrencyField from 'v-currency-field';

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/feePeriod'
import './filters/toCurrency'
import './filters/toFeePeriod'
import './filters/yesno'
import './filters/formatDate.js'
import './filters/toInstallType.js'
import './filters/toGateway.js'
import './filters/toProcessor.js'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

Vue.config.productionTip = false

Vue.use(UUID);
Vue.use(VueSweetalert2);
Vue.use(VueConfetti);
Vue.use(MediaPlayerInstaller);

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
});

Vue.use(VueLuxon, {
  templates: {},
  input: {
    zone: "America/Chicago",
    format: "iso"
  },
  output: {
    zone: "local",
    format: "short",
    locale: null,
    relative: {
      round: true,
      unit: null
    }
  }
});

Vue.use(VueTheMask);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
