import Vue from "vue";

Vue.filter('toFeePeriod', function (value) {
    if (!value) return ''
    
    let data = '';
    switch(value.toLowerCase()){
        case 'o':
            data = 'One Time';
            break;
        case 'm':
            data = 'Monthly';
            break;
        case 'y':
            data = 'Yearly';
            break;
    }
    
    return data;
});