import configs from '../../configs'
import Vuetify from "@/plugins/vuetify";
import httpClient from '@/plugins/axios';
import axios from 'axios';

const { product, theme  } = configs
const { isRTL, globalTheme } = theme

// state initial values
const state = {
    product,
    // app theme dark or light
    globalTheme,
    // themes and layout configurations
    isRTL,
    profile: {},
    token: '',
    enterprises:[],
    products: [],
    salesPeople: [],
    terminals: [],
    gateways: [],
    dealerPayFees: [],
    gatewayFees: [],
    dealerTypes: [],
    dealerPayFeeLevels: [],
    dealerPayFeePricing: [],
    productPricing: [],
    productPricingLevels: [],
    support: [],
    installLeadReps: []
}

const mutations = {
    /**
     * Theme and Layout
     */
    setGlobalTheme: (state, theme) => {
        Vuetify.framework.theme.dark = theme === 'dark'
        state.globalTheme = theme
    },
    setRTL: (state, isRTL) => {
        Vuetify.framework.rtl = isRTL
        state.isRTL = isRTL
    },
    setProfile: (state, profile) => {
        state.profile = profile;
        state.token = profile.token;
    },
    setEnterprises: (state, enterprises) => {
        state.enterprises = enterprises;
    },
    setProducts: (state, products) => {
        state.products = products;
    },
    setSalesPeople: (state, salesPeople) => {
        state.salesPeople = salesPeople;
    },
    setTerminals: (state, terminals) => {
        state.terminals = terminals;
    },
    setGateways: (state, gateways) => {
        state.gateways = gateways;
    },
    setDealerPayFees:(state, fees) => {
        state.dealerPayFees = fees;
    },
    setGatewayFees: (state, fees)=>{
        state.gatewayFees = fees;
    },
    setDealerTypes: (state, dealerTypes)=>{
        state.dealerTypes = dealerTypes;
    },
    setDealerPayFeeLevels: (state, dealerPayFeeLevels)=>{
        state.dealerPayFeeLevels = dealerPayFeeLevels;
    },
    setDealerPayFeePricing: (state, dealerPayFeePricing)=>{
        state.dealerPayFeePricing = dealerPayFeePricing;
    },
    setProductPricingLevels: (state, productPricingLevels)=>{
        state.productPricingLevels = productPricingLevels;
    },
    setProductPricing: (state, productPricing)=>{
        state.productPricing = productPricing;
    },
    setSupport: (state, support)=>{
        state.support = support;
    },
    setInstallReps: (state, installReps)=>{
        state.installLeadReps = installReps;
    }
}


const getters = {
    isAuthenticated: state => { return state.token !== ''},
    isAdmin: state => state.profile.isAdmin, /*state.profile.role === 'Admin',*/
    getUser: state => {return state.profile;},
    token: state => {return state.token;}
}

const actions = {
    login: ({ commit, dispatch }, credentials) => {
        const vm = this;
        return httpClient.post('/account/login', credentials, {withCredentials:true})
            .then(res => {

                if(res.data.success){
                    commit('setProfile', res.data)
                    dispatch('getDomainData');
                    localStorage.setItem("isAuthenticated", 'true');
                    //localStorage.setItem('token', res.data.token);
                }
                else{
                    commit('setProfile', {})
                    localStorage.setItem("isAuthenticated", 'false');
                    vm.$root.$emit('genericMessage', {message: 'User Is Not An Admin.  Please Request Access.', color: 'error'});
                }
                return res.data;
            });
    },
    logout: ({ commit }) => {
        commit('setProfile', {})
        localStorage.setItem("isAuthenticated", 'false');
        sessionStorage.clear();
    },
    getDomainData: ({commit}) => {
        
        const enterpriseQuery = httpClient.get('/enterprise');
        const productQuery = httpClient.get('/products');
        const terminalQuery = httpClient.get('/terminals');
        const salesPersonQuery = httpClient.get('/salesperson');
        const gatewayQuery = httpClient.get('/gateway');
        const dealerPayFeeQuery = httpClient.get('/dealerPayFee');
        const gatewayFeeQuery = httpClient.get('/gatewayFee');
        const dealerTypesQuery = httpClient.get('/dealerType');
        const dealerPayFeeLevelsQuery = httpClient.get('/dealerPayFeeLevels');
        const dealerPayFeePricingQuery = httpClient.get('/dealerPayFeePricing');
        const productPricingQuery = httpClient.get('/productPricing');
        const productPricingLevelsQuery = httpClient.get('/productPricingLevels');
        const supportQuery = httpClient.get('/support');
        const installLeadRepsQuery = httpClient.get('/support/installReps');

        axios.all([enterpriseQuery, productQuery, terminalQuery, salesPersonQuery, gatewayQuery, dealerPayFeeQuery, gatewayFeeQuery, dealerTypesQuery,
            dealerPayFeeLevelsQuery, dealerPayFeePricingQuery, productPricingQuery, productPricingLevelsQuery, supportQuery, installLeadRepsQuery])
            .then(axios.spread(function(enterprise, product, terminal, sales, gateway, dealerPayFee, gatewayFee, dealerType,
                dealerPayFeeLevels, dealerPayFeePricing, productPricing, productPricingLevels, support, installReps){
                commit('setEnterprises', enterprise.data);
                commit('setTerminals', terminal.data);
                commit('setSalesPeople', sales.data);
                commit('setGateways', gateway.data);
                commit('setDealerPayFees', dealerPayFee.data);
                commit('setGatewayFees', gatewayFee.data);
                commit('setProducts', product.data);
                commit('setDealerTypes', dealerType.data);
                commit('setDealerPayFeeLevels', dealerPayFeeLevels.data);
                commit('setDealerPayFeePricing', dealerPayFeePricing.data);
                commit('setProductPricing', productPricing.data);
                commit('setProductPricingLevels', productPricingLevels.data);
                commit('setSupport', support.data);
                commit('setInstallReps', installReps.data);
            }));
        
  
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
