import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import AppModule from './app';
import EnrollmentModule from './enrollment';
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: AppModule,
    enrollment: EnrollmentModule
  },
  plugins: [createPersistedState({
    key: 'state',
    storage: window.sessionStorage
  })]
})

export default store
