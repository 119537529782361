import Vue from 'vue'

Vue.filter('toGateway', (value) => {
    if(value == null || value == '') return '';

    let type = '';
    switch(value){
        case 1:
            type = 'Datacap';
            break;
        case 2:
            type = 'Clearent';
            break;
    }

    return type;
})
