import Vue from 'vue'

Vue.filter('toInstallType', (value) => {
    if(value == null || value == '') return '';
    
    let type = '';
    switch(value){
        case 1:
            type = 'In Person';
            break;
        case 2:
            type = 'Remote';
            break;
    }
    
    return type;
})
