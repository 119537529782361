<template>
  <v-app>
    <v-snackbar
        v-model="showAlert"
        :top="true"
        :right="true"
        :color="alertColor"
        timeout="5000"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="showAlert = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <router-view />
  </v-app>
</template>

<script>
import config from './configs'

export default {
  name: 'App',
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },
  data(){
    return {
      showAlert: false,
      alertMessage: '',
      alertColor:'success'
    }
  },
  mounted(){
    let vm = this;
    this.saveSuccess = (data) =>{
      vm.alertMessage = data.message;
      vm.showAlert = true;
      vm.alertColor = 'success';
    };
    this.saveFail = (data) => {
      vm.alertMessage = data.message;
      vm.showAlert = true;
      vm.alertColor = 'error';
    };
    this.loginMessage = (data) =>{
      vm.alertMessage = data.message;
      vm.showAlert = true;
      vm.alertColor = data.color;
    };
    this.genericMessage = (data) =>{
      vm.alertMessage = data.message;
      vm.showAlert = true;
      vm.alertColor = data.color;
    };

    this.$root.$on("saveSuccess", this.saveSuccess);
    this.$root.$on("saveFailed", this.saveFail);
    this.$root.$on("loginMessage", this.loginMessage);
    this.$root.$on("genericMessage", this.genericMessage);

  },
  beforeDestroy(){
    this.$root.$off("saveSuccess", this.saveSuccess);
    this.$root.$off("saveFailed", this.saveFail);
    this.$root.$off("loginMessage", this.loginMessage);
  }
};
</script>
