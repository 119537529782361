import Vue from 'vue'

Vue.filter('toProcessor', (value) => {
    if(value == null || value == '') return '';

    let type = '';
    switch(value.toUpperCase()){
        case '4DFB8B7B-4A8F-424E-B404-3AA0ED412F0D':
            type = 'Payroc';
            break;
        case 'D12EEF76-8C90-489A-9401-40B606F38800':
            type = 'Clearent';
            break;
        case '84FD0296-CDC8-4BE4-81D1-A0914CF99AA1':
            type = 'Intellipay';
            break;
    }

    return type;
})
