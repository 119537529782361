import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

export const routes = [
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/layouts/AdminLayout.vue'),
    beforeEnter (to, from, next){

      if(store.getters["app/isAuthenticated"] === false || store.getters["app/isAdmin"] === false){
        return next({path:'/login'})
      }
      return next();
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/BlankPage')
      },
      {
        path: 'enrollments',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Enrollment/Enrollments')
      },
      {
        name:'create-enrollment',
        path: 'create-enrollment',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Enrollment/CreateEnrollment')
      },
      {
        name:'edit-enrollment',
        path: 'edit-enrollment/:id',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Enrollment/EditEnrollment')
      },
      {
        name: 'review-enrollment',
        path: 'review-enrollment/:id',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Enrollment/ReviewEnrollment')
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Products/Products')
      },
      {
        name:'modify-product',
        path: 'modify-product/:id',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Products/EditProduct')
      },
      {
        name:'create-product',
        path: 'create-product',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Products/CreateProduct')
      },
      {
        path: 'terminals',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Terminals/Terminals')
      },
      {
        name:'modify-terminal',
        path: 'modify-terminal/:id',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Terminals/EditTerminal')
      },
      {
        name:'create-terminal',
        path: 'create-terminal',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Terminals/CreateTerminal')
      },
      {
        name:'dealer-pay-fees',
        path: 'dealer-pay-fees',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/DealerPayFees/DealerPayFees')
      },
      {
        path: 'sales-person',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/SalesPerson/SalesPerson')
      },
      {
        name:'modify-sales-person',
        path: 'modify-sales-person/:id',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/SalesPerson/EditSalesPerson')
      },
      {
        name:'create-sales-person',
        path: 'create-sales-person',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/SalesPerson/CreateSalesPerson')
      },
      {
        name:'create-fee',
        path: 'create-fee',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/DealerPayFees/CreateFees')
      },
      {
        name:'create-support',
        path: 'create-support',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Support/CreateSupport')
      },
      {
        name:'edit-support',
        path: 'edit-support/:id',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Support/EditSupport')
      },
      {
        name:'support',
        path: 'support',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Support/Support')
      }
    ]
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/AccountLayout.vue'),
    children:[
      {
        path: '',
        name: 'resource',
        component: () => import(/* webpackChunkName: "resource" */ '@/pages/application/Resources')
      }
    ]
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/AccountLayout.vue'),
    children: [{
      path: '',
      name: 'login',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/login')
    }]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:id',
    name: 'application',
    component: () => import(/* webpackChunkName: "application" */ '@/layouts/EnrollmentLayout.vue'),
    children:[
      {
        path:'',
        component: () => import(/* webpackChunkName: "application" */ '@/pages/application/Enroll'),
      },
      {
        path:'done',
        component: () => import(/* webpackChunkName: "application" */ '@/pages/application/Done'),
      }
    ]
  },
  {
    path: '/dealership-data/:id',
    name: 'delegated-dealership-information',
    component: () => import(/* webpackChunkName: "application" */ '@/layouts/DelegatedDealershipInfoLayout.vue'),
    children:[
      {
        path: '',
        meta:{delegated: true},
        component: () => import(/* webpackChunkName: "application" */ '@/pages/application/Enroll')
      },
      {
        path: 'complete',
        meta:{delegated: true},
        component: () => import(/* webpackChunkName: "application" */ '@/pages/application/Enroll')
      }
    ]
  },
  {
    path:'/callback/:id/:externalId',
    name: 'callback',
    component: () => import(/* webpackChunkName: "adobecallback" */ '@/layouts/EnrollmentLayout.vue'),
    children:[
      {
        path:'',
        component: () => import(/* webpackChunkName: "adobecallback" */ '@/pages/integration/AdobeCallback'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
